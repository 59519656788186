<template>
   <div>      
      <big-title-color variant-class="dark">
        <template v-slot:text>Estados de <span>cuenta</span>.</template>.
      </big-title-color>
      <template v-if="statements.length > 0">
        <vx-card class="mt-base">
          <div class="vx-row mb-6">
              <div class="vx-col xxl:w-3/4 w-full">
                <p>Consulta y descarga los últimos estados de cuenta de RedGirasol en los que encontrarás
                  tus movimientos, y más información relevante sobre tu participación en la Red.
                  Ante cualquier duda o comentario estaremos para apoyarte a través del chat de la plataforma.</p>
                  <p class="mt-4 bold underline">
                    Entre los días 6 y 7 de cada mes aparecerá disponible tu estado de cuenta.
                  </p>  
              </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <!--h6>¿Qué año te gustaría consultar?</h6-->
            </div>
          </div>

          <vs-table ref="table" :data="statements" pagination stripe :max-items="itemsPerPage" noDataText="No hay datos disponibles">
            <template slot="thead">
              <vs-th class="bold" sort-key="month">PID</vs-th>
              <vs-th class="bold" sort-key="month">MES</vs-th>
              <vs-th class="bold">PERIODO</vs-th>
              <vs-th class="bold">PDF</vs-th>
              <!-- <vs-th class="bold">XML</vs-th> -->
            </template>

            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p class="pl-3 bold">{{ tr.project_id }}</p>
                </vs-td>
                <vs-td>
                  <p class="pl-3 bold">{{ tr.month }} {{tr.year}}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium truncate">{{ tr.start_period }} al {{tr.end_period}}</p>
                </vs-td>

                <vs-td class="whitespace-no-wrap">
                  <vs-button type="line" class="px-2 py-1" @click.stop="downloadPdf(tr)" color="dark" >Descargar</vs-button>
                </vs-td>
                <!-- <vs-td class="whitespace-no-wrap">
                  <vs-button v-if="tr.has_xml" type="line" class="px-2 py-1" @click.stop="downloadXml(tr)" color="dark" >Descargar</vs-button>
                  <p v-else>--</p>
                </vs-td> -->

              </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </vx-card>
      </template>
      <vx-card v-else class="mt-base" title="¡Pronto podrás descargar tus estados de cuenta!">
        <div class="vx-row">
            <div class="vx-col xxl:w-3/4 w-full">
              <p>Próximamente podrás consultar y descargar en este apartado los últimos estados de cuenta de tu crédito con RedGirasol 
                en los que encontrarás tus movimientos y más información relevante. 
                Ante cualquier duda o comentario estaremos para apoyarte a través del chat de la plataforma.</p>
            </div>
        </div>
      </vx-card>
     <!-- Componente para segundo factor -->
     <two-factor-auth
       :user-id="UserId"
       custom-indication-message="Por favor ingresa los 6 dígitos de tu Segundo Factor de Autenticación para realizar la descarga del archivo."
       @on-code-validated="onAuth"
       @on-code-invalid="onAuthFailed" />

   </div>

</template>

<script>
import dateHelper from "@mixins/dateHelper";
import TwoFactorAuth from "@components/2fa/TwoFactorAuth";
import auth2faHelper from "@mixins/auth2faHelper";
export default {
   data() {
      return {
         selected: [],
         allStatements: [],
         statements: [],
         itemsPerPage: 10,
         monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
         isMounted: false,
         currentStatement: null,
         authAction: null,
        factorEnabled: false,
      }
   },
  components: {
     TwoFactorAuth
  },
  mixins: [dateHelper,auth2faHelper],
   mounted () {
    this.isMounted = true
   },
   async beforeMount(){
     this.showLoading(true)
    //  await this.getUserData();
     this.factorEnabled = await this.getUser2FAStatus(this.UserId);
     await this.getStatementsAvailable();
     this.showLoading(false)
   },
   computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
  },
  methods: {
    request2faCode(){
      this.$store.commit("TOGGLE_IS_2FA_POPUP_ACTIVE", true);
    },
     async getStatementsAvailable(){
       try {
         const res = await axios.get(`api/v1/applicant/${this.ApplicantId}/getStatements`);
         this.allStatements = res.data;
         this.allStatements.forEach(f => {
           this.statements.push({
             id: f.id,
             month: this.monthName(f.end_period_month),
             year: f.end_period_year,
             start_period: this.regularDateFormat(f.start_period_date),
             end_period: this.regularDateFormat(f.end_period_date),
             project_id: f.project_id,
             has_xml: f.rg_invoice_id != null
           })
         });
       }
       catch (e) {
       }
     },
    monthName(index){
      return this.monthNames[index -1];
    },
    async downloadPdf(tr){
      if(!this.factorEnabled){
        this.$vs.dialog({
          color: "warning",
          type: "confirm",
          title: "Autenticación necesaria",
          text: "Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.",
          acceptText: "Habilitar",
          cancelText: 'Cancelar',
          accept: this.goTo2FaPage,
        });

        return;
      }

       this.currentStatement = tr;
       this.authAction = 1;
       this.request2faCode();
    },
    downloadXml(tr){
      this.currentStatement = tr;
      this.authAction = 2;
      this.request2faCode();
    },
    async onAuth(code){
       if(this.authAction === 1){
         await this.doDownloadPdf(code);
       }
       else if(this.authAction === 2){
         await this.doDownloadXml(code);
       }
    },
    async onAuthFailed(code){
    },
    async doDownloadPdf(code){
      try {
        let url = `${this.ApiDomain}/api/v1/applicant/${this.ApplicantId}/downloadStatementPdf/${this.currentStatement.id}?access_token=${this.AccessToken}&2fa_restart_cookie=true&code=${code}`;
        window.open(url, "_blank");
        // se genera log de accion
        this.saveApplicantLogAction("LC12-26");
      }
      catch (e) {
        this.warn(e)
        this.showError(e);
      }
    },
    async doDownloadXml(code){
      try {
        let url = `${this.ApiDomain}/api/v1/applicant/${this.ApplicantId}/downloadStatementXml/${this.currentStatement.id}?access_token=${this.AccessToken}&2fa_restart_cookie=true&code=${code}`;
        window.open(url, "_blank");
        // se genera log de accion
        this.saveApplicantLogAction("LC12-26");
      }
      catch (e) {
        this.warn(e)
        this.showError(e);
      }
    },
    async goTo2FaPage(){
      await this.$router.push({name: 'auth2FaSolicitante'});
    },
    showError(e){
       let err = "Ha ocurrido un error, intenta más tarde.";
       if(e.response && e.response.data && e.response.data.message){
         err = e.response.data.message;
       }
       this.errorNotifDialog('Error en la petición', err);
    },
  }
}
</script>
