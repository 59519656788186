export default {
  methods: {
    async getUser2FAStatus(user_id){
      try {
        const res = await axios.get(`api/perfil/get2FAStatus/${user_id}`);
        return res.data.factorEnabled;
      }
      catch (error) {
        return false;
      }
    },
  }
}