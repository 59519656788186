<template>
  <div>
    <vs-popup :active.sync="is2FaPopupActive" title="Confirma que eres tú">
      <div class="p-4 pt-0">
        <div>
          <h6 v-if="!validating && !customIndicationMessage" class="title-dark">Por favor ingresa el código de 6 dígitos.</h6>
          <h6 v-if="!validating && customIndicationMessage" class="title-dark">{{customIndicationMessage}}</h6>
          <h6 v-if="validating" class="title-dark">Validando...</h6>
          <vs-alert v-if="errorMssg" color="danger" class="mt-3 mb-3" style="height: auto">
            {{ errorMssg }}
            <div class="black" v-if="showInstructions">
              <br>Si el problema persiste, es posible que tu app de autenticación
              (Autenticador de Google, Authy, etc.) no esté sincronizada.<br><br>
              Intenta <a :href="$sanitizeUrl(twoFactorCodeUrl)" target="_blank" rel="noopener noreferrer" class="underline bold black"> sincronizar tu app </a> de autenticación o <a :href="$sanitizeUrl('perfil/seguridad/2fa')" rel="noopener noreferrer" class="underline bold black">reestablece tu segundo factor de autenticación</a>.
            </div>
          </vs-alert>

          <div v-if="showExtraOptions && user.phone" class="mt-3">
              <div class="mb-2">
                También podemos enviarte un código al <b>{{ visibleLastNChars(user.phone, 4) }}</b> por:<br>
              </div>
              <div v-if="user.phone_country_calling_code != '--' && user.phone_country_calling_code != '+1'" class="flex items-center mb-2">
                <vs-icon icon="sms" size="small" color="primary"></vs-icon>
                <label class="clickable-img underline bold black ml-2" @click="sendAlternativeCode(false)">Mensaje de texto (SMS)<br></label>
              </div>
              <div class="flex items-center">
                <image-from-source path="/img/wp-green.png" style="width:24px;" />
                <label class="clickable-img underline bold black ml-2" @click="sendAlternativeCode(true)">WhatsApp</label>
              </div>
          </div>

          <div v-if="alternativeCodeSent" class="mt-5">
                Te enviamos un código por {{ methodCodeSent }} al {{ visibleLastNChars(user.phone, 4) }}<br><br>
                ¿No lo recibiste? 
                <label v-if="resendEnabled" class="clickable-img underline bold black" @click="sendAlternativeCode(whatsappSent)">Reenviar</label>
                <template v-else>
                  Reenviar en <span class="bold">00:{{resendTimer}} </span>
                </template>                
                <br><br>
                O recibe tu código por:<br><br>
                <div v-if="whatsappSent && user.phone_country_calling_code != '+1'" class="flex items-center mb-2">
                  <vs-icon icon="sms" size="small" color="primary"></vs-icon>
                  <label class="clickable-img underline bold black ml-2" @click="sendAlternativeCode(false)">Mensaje de texto (SMS)<br></label>
                </div>
                <div v-else class="flex items-center">
                  <image-from-source path="/img/wp-green.png" style="width:24px;" />
                  <label class="clickable-img underline bold black ml-2" @click="sendAlternativeCode(true)">WhatsApp</label>
                </div>
          </div>

          <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <div class="flex items-center flex-grow justify-left mt-1">
                <vs-input
                  type="text"
                  v-model.lazy="code"
                  name="code"
                  v-validate="'required|numeric'"
                  maxlength="6"
                  autocomplete="off"
                  :disabled="validating"
                  v-on:keyup.enter="sendCode"
                  placeholder="Ingresa el código" />
                <vs-button :id="buttonId" :data-projectid="currentProjectId" class="statement-btn ml-6" color="primary" :disabled="validating" @click="sendCode" >Enviar</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper";

export default {
  name: "TwoFactorAuth",
  mixins: [formatHelper],
  props: {
    userId: {
      type: [String,Number],
      required: true
    },
    customIndicationMessage: {
      type: String,
      required: false,
      default: null
    },
    customErrorMessage: {
      type: String,
      required: false,
      default: null
    },
    currentProjectId: {
      type: [String,Number],
      required: false,
      default: null
    },
    buttonId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      code: null,
      errorMssg: null,
      validating: false,
      twoFactorCodeUrl: "https://soporte.redgirasol.com/support/solutions/articles/48001243819-el-c%C3%B3digo-de-mi-segundo-factor-de-autenticaci%C3%B3n-es-incorrecto-qu%C3%A9-puedo-hacer-",
      showInstructions:false,
      showExtraOptions: false,
      alternativeCodeSent:false,
      whatsappSent:false,
      resendTimer: 60,
      timer:null,
      resendEnabled:false
    };
  },
  watch: {
    is2FaPopupActive(n){
      if(n){
        this.validating = false;
        this.errorMssg = null;
        this.code = null;
        this.showInstructions = false;
        this.showExtraOptions = false;
        this.alternativeCodeSent = false;
        this.whatsappSent = false;
        this.timer = null;
        this.resendTimer = 60;
        this.resendEnabled = false;
      }
    }
  },
  computed: {
    is2FaPopupActive: {
      get() {
        return this.$store.state.is2faPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_2FA_POPUP_ACTIVE", val);
      }
    },
    methodCodeSent: function (){
      return this.whatsappSent? "WhatsApp":"SMS";
    }
  },
  methods: {
    async sendCode(){
      this.errorMssg = null;
      if(this.code !== null){
        if(this.code.length === 6){
          const newCode = this.code;
          this.code = null;
          await this.$emit('on-code-sent', newCode);
          if (this.alternativeCodeSent) {
            await this.validateAlternativeCode(newCode);
          } else {
            await this.prevalidate2Fa(newCode);
          }
          return;
        }
      }
      this.errorMssg = "Ingresa un código válido de 6 dígitos.";
    },
    async prevalidate2Fa(newCode){
      this.showLoading(true)
      this.showInstructions = false;
      this.validating = true
      try {
        let payload = {
          "2fa_restart_cookie": true,
          "code": newCode,
        }
        await axios.post(`/api/v1/userInfo/${this.userId}/prevalidate2fa`, payload);
        this.is2FaPopupActive = false;
        this.$emit('on-code-validated', newCode);
      }
      catch (e) {
        //this.is2FaPopupActive = false;
        //this.errorNotifDialog("Segundo Factor de Autenticación", "El código ingresado es inválido, por favor revisa los datos e intenta de nuevo.");
        this.showExtraOptions = true;
        this.validating = false
        this.errorMssg = this.customErrorMessage ?? "El código es incorrecto. Intenta nuevamente.";
        if (!this.customErrorMessage) {
          this.showInstructions = true;
        }
        this.$emit('on-code-invalid', newCode);
      }
      this.showLoading(false)
    },
    async sendAlternativeCode(isWhatsapp){
        this.errorMssg = null;
        this.whatsappSent = isWhatsapp;
        this.showExtraOptions = false;
        this.setUpTimerConfig();
        this.initNipCounter();
        this.alternativeCodeSent = true;
        this.showLoading(true, "Enviando el código...");
        try {
          let payload = {
              "user_id": this.userId,
              "is_whatsapp": isWhatsapp,
          }
          await axios.post(`/api/v1/user-info/send-verification-code-2fa`, payload);
          this.showSuccess("Código enviado exitosamente");
        } catch (e) {
          this.error_message =
            "Ocurrió  un error al obtener los datos, inténtalo mas tarde.";
        }
        this.showLoading(false);
    },
    async validateAlternativeCode(newCode){
        this.errorMssg = null;
        this.showLoading(true, "Validando el código...");
        try {
          let payload = {
              "user_id": this.userId,
              "code": newCode,
          }
          await axios.post(`/api/v1/user-info/validate-verification-code-2fa`, payload);
          this.is2FaPopupActive = false;
          this.$emit('on-code-validated', newCode);
        } catch (e) {
          this.showInstructions =false;
          this.errorMssg = "El código que ingresaste no es válido. Intenta nuevamente.";
          this.$emit('on-code-invalid', newCode);
        }
        this.showLoading(false);
    },
    setUpTimerConfig() {
      window.clearInterval(this.timer);
      this.resendTimer = 60;
      this.resendEnabled = false;
    },
    initNipCounter() {
      this.timer = setInterval(() => {
        --this.resendTimer;
        if(this.resendTimer == 0){
          this.resendEnabled = true;
          clearInterval(this.timer);
        }
      }, 1000);
    },
  },
};
</script>